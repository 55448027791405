<template>
  <div id="app">
    <div class="bar bar-top">

    </div>

    <div class="bar bar-btm">
      
    </div>
    
    <div class="bar-item top" v-if="showTop"></div>
    <div class="bar-item btm" v-if="showBtm"></div>
    <nav class="nav">
      <div class="logo" @click="$router.push('/')">
        <img class="logo-img" src="/images/logo.png" />
      </div>
      <ul class="nav-ul par">
        <li class="nav-li" v-for="(nav, i) in navList" :key="i">
          <div
            class="nav-text"
            :class="{ active: nav.path === $route.path }"
            @click="chooseNav(nav)"
          >
            {{ nav.title }}
            <div class="" v-if="i === 0">
              <template v-for="(str, i) in nameList">
                <span :key="i">&nbsp;</span>
                <template v-for="(d, j) in str">
                  <span
                    :key="`${i}_${j}`"
                    :style="{ color: colorList[j % 3] }"
                    >{{ d }}</span
                  >
                </template>
              </template>
            </div>
          </div>
          <ul class="nav-ul sub" v-if="nav.children && nav.children.length > 0">
            <li
              class="nav-li"
              v-for="(childNav, index) in nav.children"
              :key="index"
            >
              <div class="nav-text" @click.self="chooseNav(childNav)">
                {{ childNav.title }}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <div class="line">-------------</div>
      <ul class="nav-ul">
        <li class="nav-li" v-for="(pro, i) in projectList" :key="i">
          <div
            class="nav-text pro"
            :class="{ active: $route.path === `/project/${i}` }"
            @click.self="pathToProject(i)"
          >
            {{ pro.name }}
          </div>
        </li>
      </ul>
      <div class="contact">
        <div class="left">
          <div>Contact Us:</div>
          <div>Email: info@sklf.com.cn</div>
          <div>
            Address: 4th floor, F13 block, F518 Creative Park, Baoyuan Road,
            Baoan District, Shenzhen, China, 518102
          </div>
        </div>
        <div class="right">
          <img class="contact-img" src="/images/logo1.png" alt="" />
          <div>@2023 Cubic Space</div>
        </div>
      </div>
    </nav>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['projectList']),
    showTop() {
      const {path} = this.$route
      return path.startsWith('/art') || path === '/' || path.startsWith('/about')
    },
    showBtm() {
      const {path} = this.$route
      return path.startsWith('/art')
    }
  },
  data() {
    return {
      nameList: ['CUBIC', 'SPACE'],
      colorList: ['#0044b6', '#1ca9ff', '#67dfdf'],
      choosedIndex: '',
      navList: [
        { title: 'About', path: '/about' },
        { title: '2D Art', path: '/art/2d' },
        { title: '3D Art', path: '/art/3d' },
        { title: 'VFX', path: '/art/vfx' },
        { title: 'Animation', path: '/art/ani' },
        { title: 'Motion Capture Studio', path: '/art/motion' },
        { title: 'Game Development', path: '/art/game' }
      ]
    }
  },
  methods: {
    chooseNav(nav) {
      this.$router.push(nav.path)
    },
    pathToProject(index) {
      if (this.$route.path === `/project/${index}`) return
      this.$router.push(`/project/${index}`)
    }
  }
}
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
}
html,
body,
div,
p,
li,
ul,
span,
i,
nav aside,
footer {
  margin: 0;
  padding: 0;
}
ul,
li,
ol,
dl,
dt,
dd {
  list-style: none;
}

::-webkit-scrollbar {
  display: none;
}
.content {
  background: url('./assets/images/main_bg.png') no-repeat center;
  background-size: cover;
  font-family: 'YuGo';
}

.nav {
  font-family: 'MSYHBD';
}

@font-face {
  font-family: 'MSYH';
  src: url('./assets/fonts/MSYH.TTC');
}

@font-face {
  font-family: 'MSYHBD';
  src: url('./assets/fonts/MSYHBD.otf');
}

@font-face {
  font-family: 'MSYHL';
  src: url('./assets/fonts/MSYHL.TTC');
}

@font-face {
  font-family: 'YuGo';
  src: url('./assets/fonts/YuGothB.ttc');
}

#app {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
}
</style>

<style lang="scss" scoped>
$nav-width: 450px;
.logo {
  cursor: pointer;
  // margin: 60px 17px;
  margin: 60px 22px 60px  39px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 26px;
  &-img {
    width: 100%;
    display: block;
  }
}
.bar-item {
  position: fixed;
  z-index: 2;
  width: 100%;
  &::before,
  &::after {
    content: '';
    height: 25px;
    display: block;
    position: absolute;
  }
  &::before {
    width: $nav-width;
    background: $color-nav-active;
  }
  &::after {
    display: block;
    position: absolute;
    left: $nav-width;
    // right: 0;

    width: calc(100% - $nav-width);
    background: $color-nav-hover;
  }
  &.top {
    top: 0;
  }
  &.btm {
    bottom: 0;
    &::before,
    &::after {
      bottom: 0;
    }
  }
}

.nav {
  // position: absolute;
  font-weight: normal;
  position: fixed;
  top: 0;
  // left: 0;
  bottom: 0;
  height: 100vh;
  width: $nav-width;
  background: url('./assets/images/nav_bg.png') no-repeat center;
  background-size: cover;
  &-text {
    // color: #8d8c8c;
    color: $base-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    &.pro {
      font-size: size(11px);
      // line-height: size(14px);
    }
    &.active {
      &::after {
        content: '';
        display: block;
        border: 6px solid;
        border-color: transparent transparent transparent $color-nav-active;
        margin-left: 8px;
        margin-top: 2px;
      }
    }
    &.active {
      color: $color-nav-active;
    }
    &:hover {
      color: $color-nav-hover;
      &::after {
        border-color: transparent transparent transparent $color-nav-hover;
      }
    }
  }
  &-li {
    color: #8d8c8c;
    font-size: size(13px);
    line-height: size(16px);
    // padding: 0 size(10px);
    padding-left: $nav-left-margin;
    position: relative;
    > .nav-ul {
      position: absolute;
      z-index: 4;
      left: 100%;
      padding: 10px 15px;
      background: #000;
      display: none;
      top: -50%;
    }
    &:hover {
      > .nav-ul {
        display: unset;
      }
    }
  }
}
.content {
  margin-left: $nav-width;
  height: 100%;
}
.line {
  width: 30%;
  margin: 14px 0 14px $nav-left-margin;
  // color: $base-color;
  // letter-spacing: 5px;
  background: $base-color;
  height: 2px;
}
.contact {
  position: absolute;
  padding: 0 size(10px);
  bottom: 50px;
  color: $base-color;
  font-size: 14px;
  display: flex;
  font-family: 'Microsoft Yahei';
  align-items: center;
  .left {
    flex: 1;
  }
  .right {
    text-align: center;
    font-size: 10px;
    margin-left: 10px;
    img {
      width: 60px;
      height: 60px;
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    width: 100px;
    flex-shrink: 0;
  }
}

@media screen and (min-width: 1200px) and (max-width:1440px) {
  $nav-width: 300px;
  .nav {
    width: $nav-width;
    &-li {
      font-size: 16px;
      line-height: 24px;
    }
    &-text.pro {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .content {
    margin-left: $nav-width;
  }
  .bar-item {
    &::before, &::after {height: 28px;}
    &::before {
      width: $nav-width;
    }
    &::after {
      left: $nav-width;
      // right: 0;

      width: calc(100% - $nav-width);
    }
  }
  .right {
    display: none;
  }
}


@media screen and (min-width: 768px) and (max-width: 1199px) {
  $nav-width: 220px;
  .nav {
    width: $nav-width;
    &-li {
      font-size: 16px;
      line-height: 24px;
    }
    &-text.pro {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .content {
    margin-left: $nav-width;
  }
  .bar-item {
    &::before, &::after {height: 16px;}
    &::before {
      width: $nav-width;
    }
    &::after {
      left: $nav-width;
      // right: 0;

      width: calc(100% - $nav-width);
    }
  }
  .right {
    display: none;
  }
}


</style>
