<template>
  <div class="home">
    <div
      class="home-imgitem wow fadeIn"
      :data-wow-delay="`${i * 0.2}s`"
      v-for="(item, i) in projectList"
      :key="i"
      @click="pathToProject(i)"
    >
      <img class="home-img" :src="`/images/project_${i+1}.jpg`" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'HomeView',
  computed: {
    ...mapGetters(['projectList'])
  },
  components: {},
  data() {
    return {
      img: require('../assets/images/pic1.png')
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initWOW()
    })
  },
  methods: {
    pathToProject(index) {
      this.$router.push(`/project/${index}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  min-height: 100%;
  background: url('../assets/images/main_bg.png') no-repeat center;
  background-size: cover;
  &-imgitem {
    width: 50%;
    overflow: hidden;
    float: left;
    position: relative;
    cursor: pointer;
    &:hover {
      z-index: 1;
      img {
        transform: scale(1.1);
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 2;
      }
    }
  }
  &-img {
    transition: transform 0.3s;
    display: block;
    width: 100%;
  }
}
.story-box {
  display: flex;
}
</style>
