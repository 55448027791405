<template>
  <div class="art-detail-box">
    <div
      class="art-detail-item wow fadeIn"
      data-wow-duration="2s"
      :data-wow-delay="`${i * 0.2}s`"
      v-for="(pro, i) in projectList"
      :key="i"
      @click="$router.push(`/project/${i}`)"
    >
      <img class="art-detail-img" :src="`/images/project_${i + 1}.jpg`" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
mapGetters
export default {
  computed: {
    ...mapGetters(['projectList']),
  },
  mounted () {
    this.$nextTick(() => {
      this.initWOW()
    })
  }
}
</script>

<style lang="scss" scoped>
$border-color: $color-nav-hover;
$border-width: 2px;
.art {
  &-detail {
    &-box {
      display: flex;
      flex-wrap: wrap;
      margin: 184px 135px 0;
      position: relative;
      border-left: $border-width solid $border-color;
      border-top: $border-width solid $border-color;
    }
    &-item {
      width: calc(100% / 3);
      box-sizing: border-box;
      overflow: hidden;
      border-bottom: $border-width solid $border-color;
      border-right: $border-width solid $border-color;
      &:hover {
        .art-detail-img {
          transform: scale(1.1);
        }
      }
    }
    &-img {
      transition: transform 0.3s;
      display: block;
      width: 100%;
    }
  }
}

</style>
