<template>
  <div class="about-box">
    <div class="about-infobox">
      <div class="about-infobox-item">
        <div
          class="about-item about-imgbox aboutimg wow bounceInLeft"
          data-wow-delay="0.5s"
        >
          <img class="about-img" src="/images/about_credits.png" alt="" />
        </div>
        <div class="about-item about-text text1 wow bounceInRight">
          <h2 class="about-item-title">
            <span>About</span> <span class="nn active">CUBIC</span>
            <span class="nn hover">SPACE</span>
          </h2>
          <div>
            Situated in the largest city of South China, Cubic Space is the
            largest animation&VFX studio in China that is behind many global
            hits developed by top Chinese game companies.

            <br />
            <br />
            With also strongest team in 2D and 3D art, and extensive engine
            knowledge with programming and testing team, we provide full art and
            development services to developments around the world.
          </div>
        </div>
      </div>

      <div class="about-infobox-item">
        <div
          class="about-item about-text text2 about-clients about-client wow bounceInLeft"
        >
          <h2 class="about-item-title">Clients:</h2>
          <div>
            <p
              class=""
              :data-wow-delay="`${getTime(i * 0.05)}s`"
              v-for="(item, i) in clientsList"
              :key="i"
            >
              {{ item }}
            </p>
          </div>
        </div>
        <div
          class="about-item about-imgbox clientsimg wow bounceInRight"
          data-wow-delay="0.5s"
        >
          <img class="about-img" src="/images/about_about.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutView',
  data() {
    return {
      clientsList: [
        'Tencent',
        'Netease',
        'miHoYo',
        'ByteDance',
        'Lilith Games',
        'FunPlus',
        'Outfit7',
        'Jam City',
        'Lingxi Games',
        'IGG',
        'Yotta Games',
        '37Games',
        'Topwar',
        'Moonton',
        'Magic Tavern',
        'Happy Elements',
        'Century Games',
        'Perfect World',
        'Tap4Fun',
        'ELEX',
        'YOOZO'
      ]
    }
  },
  methods: {
    getTime (num) {
      return Number(num.toFixed(5))
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initWOW()
    })
  }
}
</script>

<style lang="scss" scoped>
.about {
  &-box {
    background: url('../assets/images/main_bg.png') no-repeat center;
    background-size: cover;
  }
  &-infobox {
    &-item {
      display: flex;
      // align-items: center;
      flex-wrap: wrap;
      // padding: 35px 25px;
      line-height: 42px;
    }
  }
  &-clients {
    text-align: center;
  }
  &-item {
    width: 50%;
    font-size: 27px;
    color: $base-color;
    &-title {
      color: $color-nav-hover;
      margin-bottom: 36px;
      font-weight: 600;
      .nn {
        margin-left: 7px;
        color: $base-color;
        &.active {
          color: $color-nav-active;
        }
        &.hover {
          color: $color-nav-hover;
        }
      }
    }
    h2 {
      font-size: 34px;
      font-weight: 400;
      line-height: 48px;
    }
    &-long {
      width: 100%;
      text-align: center;
      padding: 30px;
    }
  }
  &-imgbox {
    &.aboutimg {
      width: 675px;
    }
    &.clientsimg {
      width: 796px;
    }
  }
  &-img {
    display: block;
    width: 100%;
  }
  &-text {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // padding: 0 80px;
    margin: 170px 138px 20px 106px;
    box-sizing: border-box;
    line-height: 34px;
    flex: 1;
    &.text2 {
      margin: 28px 100px 20px 78px;
      line-height: 48px;
      .about-item-title {
        margin-bottom: 6px;
      }
    }
  }
  &-client {
    font-size: 20px;
    font-style: italic;
    // h2 {
    //   color: $color-nav-active;
    //   font-size: 36px;
    // }
  }
}
.align-right {
  align-items: flex-end;
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .about {
    &-item {
      line-height: 32px;
      font-size: 18px;
      h2 {
        font-size: 22px;
        line-height: 36px;
      }
    }
    &-client {
      font-size: 18px;
      h2 {
        font-size: 22px;
        line-height: 36px;
      }
    }
    &-infobox-item {
      line-height: 28px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .about {
    &-item {
      line-height: 32px;
      font-size: 18px;
      h2 {
        font-size: 22px;
        line-height: 36px;
      }
    }
    &-client {
      font-size: 18px;
      h2 {
        font-size: 22px;
        line-height: 36px;
      }
    }
    &-infobox-item {
      line-height: 28px;
    }
  }
}
</style>
