import { render, staticRenderFns } from "./ImageBox.vue?vue&type=template&id=b979a550&scoped=true"
import script from "./ImageBox.vue?vue&type=script&lang=js"
export * from "./ImageBox.vue?vue&type=script&lang=js"
import style0 from "./ImageBox.vue?vue&type=style&index=0&id=b979a550&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.33_css-loader@6.11.0_webpack@5.93.0__lodash@4.17.21__w6pyxzxa27oyw7xa73uxpx6xoy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b979a550",
  null
  
)

export default component.exports