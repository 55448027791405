import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showLargeImage: false,
    dataList: [
      {
        name: 'Apex Legends Mobile',
        desc: [
          'Cubic Space is providing character modeling, environment modeling and animation support for Apex Legends Mobile.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        videoIds: '6QoYsKCwa-o', // https://www.youtube.com/watch?v=
        poster: '',
        imgList: []
      },
      {
        name: 'Call of Duty M',
        desc: [
          'Cubic Space is providing animation and motion capture support for Call of Duty M.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        videoIds: 'NpnAxWEAtmc',
        poster: '',
        imgList: []
      },
      {
        name: 'Diablo Immortal M',
        desc: [
          'Cubic Space is providing character concept, character modeling, environment modeling, animation and VFX support for Diablo Immortal M.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        videoIds: 'mfm0VHQJZxE', // 2T7x1bObMYI
        poster: '',
        imgList: []
      },
      {
        name: 'Genshin Impact',
        desc: [
          'Cubic Space is providing animation support for Genshin Impact.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        videoIds: '1Ip43DfbkUw',
        poster: '',
        imgList: []
      },
      {
        name: 'Honor of Kings',
        desc: [
          'Cubic Space is providing character modeling, animation and VFX support for Honor of Kings.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        videoIds: '6Yh6VODcRys',
        poster: '',
        imgList: []
      },
      {
        name: 'League of Legends: Wild Rift',
        desc: [
          'Cubic Space is providing character concept, character Modeling, animation and VFX support for League of Legends: Wild Rift.',
          'COMING SOON (UNDER NDA)'
        ],
        type: 'video',
        poster: '',
        videoIds: '6QoYsKCwa-o',
        imgList: []
      }
    ]
  },
  getters: {
    projectList: (state) => state.dataList,
    showLargeImage: (state) => state.showLargeImage
  },
  mutations: {
    changeLargeImage(state, data) {
      state.showLargeImage = data
    }
  },
  actions: {},
  modules: {}
})
